import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Divider,
  Typography,
  TextField,
  Container,
  Link,
  Button,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { withAlert } from "../recoil/snackbar";
import authAtom from "../recoil/auth";
import { useMutation } from "react-query";
import axios from "axios";
import { Eye, EyeSlash } from "phosphor-react";

const Login = () => {
  const navigate = useNavigate();
  const setAuth = useSetRecoilState(authAtom);
  const openAlert = useSetRecoilState(withAlert);
  const [data, setData] = useState({
    email: null,
    password: null,
  });
  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const token = localStorage.getItem("otpToken");
    if (token) {
      setData((prevData) => ({ ...prevData, otpToken: token }));
    }
  }, []);

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const { isLoading, mutate } = useMutation(
    (data) => {
      return axios.post(
        `${process.env.REACT_APP_SERVICE_BASE_URL}/api/authentication/login`,
        data,
        {
          validateStatus: function (status) {
            return status <= 500;
          },
        }
      );
    },
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          setAuth(res.data);
          localStorage.setItem("boomsms-auth", JSON.stringify(res.data));
          navigate("/");
          localStorage.removeItem("otpToken");
        } else {
          openAlert({
            status: res.status,
            detail:
              typeof res.data.message === "string"
                ? res.data.message
                : res.data.message[0],
          });
        }
      },
    }
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    mutate(data);
  };

  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {/* ----------------------LEFT SECTION START---------------------- */}

      <Box
        sx={{
          flexGrow: 0,
          display: { lg: "flex", xs: "none" },
          backgroundColor: "primary.light",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          width: "500px",
        }}
      >
        <Box sx={{ padding: "50px" }}>
          <Box
            component="img"
            src="/assets/BoomSMSLogo.png"
            sx={{
              height: "40px",
              objectFit: "cover",
            }}
          />
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              color: "primary.main",
              marginTop: "10px",
              textTransform: "uppercase",
            }}
          >
            Reach Your Thousands of Customers Within Minutes
          </Typography>
          <Typography
            sx={{
              marginTop: "10px",
              color: "secondary.light",
            }}
          >
            Extend Your Market and boost your revenue with BOOM SMS Service
          </Typography>
        </Box>
        <Box
          component="img"
          src="/assets/login-vector.png"
          sx={{
            marginTop: "30px",
            width: "350px",
            objectFit: "cover",
          }}
        />
      </Box>

      {/* -----------------RIGHT SECTION START------------------------ */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          height: "100vh",
          position: "relative",
          overflowX: "hidden",
        }}
      >
        <Typography
          sx={{
            display: { xs: "none", lg: "block" },
            fontSize: "0.8rem",
            padding: "2rem",
            position: "absolute",
            right: "0px",
            top: "0px",
            userSelect: "none",
            zIndex: 3,
          }}
        >
          Don't have an account?
          <Button
            size="small"
            component="span"
            onClick={() => navigate("/register")}
          >
            Register.
          </Button>
        </Typography>

        <Box sx={{ zIndex: 2, width: "100%" }}>
          <Container
            maxWidth="xs"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "flex-start",
              gap: "40px",
            }}
          >
            <Box
              component="img"
              src="/assets/BoomSMSLogo.png"
              sx={{
                display: { lg: "none" },
                height: "40px",
                objectFit: "cover",
              }}
            />

            <Box
              component="form"
              sx={{ width: "100%" }}
              onSubmit={handleSubmit}
            >
              <Typography
                variant="h6"
                sx={{ color: "secondary.main", fontWeight: 700 }}
              >
                Login to BOOM SMS
              </Typography>
              <Divider sx={{ marginTop: "20px" }}>
                <Chip label="Welcome" />
              </Divider>
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  marginTop: "20px",
                  marginBottom: "8px",
                  color: "secondary.light",
                }}
              >
                Email
              </Typography>
              <TextField
                size="small"
                fullWidth
                name="email"
                onChange={handleOnChange}
              />
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  marginTop: "20px",
                  marginBottom: "8px",
                  color: "secondary.light",
                }}
              >
                Password
              </Typography>
              <TextField
                size="small"
                type={showPassword ? "text" : "password"}
                fullWidth
                name="password"
                onChange={handleOnChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <Eye size={20} />
                        ) : (
                          <EyeSlash size={20} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Link
                href="/resetpassword"
                sx={{ float: "right", cursor: "pointer" }}
              >
                forgot password?
              </Link>
              <Box height="20px" />
              <LoadingButton
                variant="contained"
                disableElevation
                loading={isLoading}
                type="submit"
                sx={{
                  width: "50%",
                  fontWeight: 700,
                }}
              >
                Login
              </LoadingButton>
            </Box>
            <Typography
              sx={{
                display: { xs: "block", lg: "none" },
                fontSize: "0.8rem",
                width: "100%",
                textAlign: "center",
                userSelect: "none",
                zIndex: 3,
              }}
            >
              Don't have an account?{" "}
              <Link href="register" sx={{ fontWeight: 700, cursor: "pointer" }}>
                Register.
              </Link>
            </Typography>
          </Container>
        </Box>

        {/*-----------------BACKGROUND IMGAES---------------------------------------*/}
        <Box
          component="img"
          src="/assets/plane.svg"
          sx={{
            display: { xs: "none", lg: "block" },
            height: "100vh",
            objectFit: "cover",
            position: "absolute",
            right: "-50px",
            overflow: "hidden",
            zIndex: 1,
          }}
        />
        <Box
          component="img"
          src="/assets/login-vector.png"
          sx={{
            display: { lg: "none" },
            height: "30vh",
            opacity: 0.1,
            objectFit: "cover",
            position: "absolute",
            overflow: "hidden",
            zIndex: 1,
          }}
        />
      </Box>
    </Box>
  );
};

export default Login;
