import {
  Box,
  Button,
  Container,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { withAlert } from "../recoil/snackbar";
import { useCallback, useEffect, useState } from "react";
import { useMutation } from "react-query";
import axios from "axios";
import OTPInputField from "../components/common/OTPInput";
import { LoadingButton } from "@mui/lab";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const openAlert = useSetRecoilState(withAlert);
  const baseURL = process.env.REACT_APP_SERVICE_BASE_URL;

  const queryParams = new URLSearchParams(location.search);
  const queryEmail = queryParams.get("email") || "";
  const queryOtp = queryParams.get("otp") || "";

  const [data, setData] = useState({ email: queryEmail, otp: queryOtp });
  const [emailValidateError, setEmailValidateError] = useState("");
  const [timer, setTimer] = useState(0);

  const formatTime = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const { isLoading: isEmailLoading, mutate: emailMutate } = useMutation(
    async (data) => {
      const response = await axios.patch(
        `${baseURL}/api/authentication/password-reset`,
        data
      );
      return response;
    },
    {
      onSuccess: (res) => {
        if (res.status === 200) {
          openAlert({
            status: 200,
            detail: "OTP sent to your email",
          });
          setTimer(5 * 60);
        }
      },
      onError: (error) => {
        openAlert({
          status: 500,
          detail: error.response.data.message
            ? error.response.data.message
            : "There was an error by sending OTP.",
        });
        setTimer(0);
      },
    }
  );

  const { isLoading: isResetPasswordLoading, mutate: resetPasswordMutate } =
    useMutation(
      async (data) => {
        const response = await axios.post(
          `${baseURL}/api/authentication/validate-otp`,
          data
        );
        return response;
      },
      {
        onSuccess: (res) => {
          if (res.status === 200 && res.data.otpToken) {
            openAlert({
              status: 200,
              detail:
                "Password reset successfully.Please add your new password",
            });
            localStorage.setItem("otpToken", res.data.otpToken);
            navigate("/changepassword", { state: { email: res.data.email } });
          }
        },
        onError: (error) => {
          openAlert({
            status: 500,
            detail: error.response.data.message
              ? error.response.data.message
              : "There was an error when trying to reset your password.",
          });
        },
      }
    );

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSendOtp = (e) => {
    e.preventDefault();
    if (data.email.trim() === "") {
      setEmailValidateError("Please fill your email!");
    } else {
      setEmailValidateError("");
      emailMutate({ email: data.email });
    }
  };

  useEffect(() => {
    if (timer > 0) {
      const countdown = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(countdown);
    }
  }, [timer]);

  const handleSubmit = useCallback(
    (e, email = data.email, otp = data.otp) => {
      if (e) e.preventDefault();
      if (email === "" || otp === "") {
        openAlert({
          status: 400,
          detail: "Please fill all fields!",
        });
        return;
      }
      resetPasswordMutate({ email, otp });
    },
    [data.email, data.otp, openAlert, resetPasswordMutate]
  );

  useEffect(() => {
    if (queryEmail && queryOtp) {
      handleSubmit(null, queryEmail, queryOtp);
    }
  }, [queryEmail, queryOtp, handleSubmit]);

  const handleComplete = (otp) => {
    setData({ ...data, otp });
  };
  return (
    <Box
      sx={{
        display: "flex",
      }}
    >
      {/* ----------------------LEFT SECTION START---------------------- */}
      <Box
        sx={{
          flexGrow: 0,
          display: { lg: "flex", xs: "none" },
          backgroundColor: "primary.light",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh",
          width: "500px",
        }}
      >
        <Box sx={{ padding: "50px" }}>
          <Box
            component="img"
            src="/assets/BoomSMSLogo.png"
            sx={{
              height: "50px",
              objectFit: "cover",
            }}
          />
          <Typography
            variant="h4"
            sx={{
              fontWeight: 700,
              color: "primary.main",
              marginTop: "10px",
              textTransform: "uppercase",
            }}
          >
            Reach Your Thousands of Customers Within Minutes
          </Typography>
          <Typography
            sx={{
              marginTop: "10px",
              color: "secondary.light",
            }}
          >
            Extend Your Market and boost your revenue with BOOM SMS Service
            </Typography>
        </Box>
        <Box
          component="img"
          src="/assets/login-vector.png"
          sx={{
            marginTop: "30px",
            width: "350px",
            objectFit: "cover",
          }}
        />
      </Box>
      {/* -----------------RIGHT SECTION START------------------------ */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: 1,
          height: "100vh",
          position: "relative",
          overflowX: "hidden",
        }}
      >
        <Box sx={{ zIndex: 2, width: "100%" }}>
          <Container
            maxWidth="xs"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              alignItems: "flex-start",
              gap: "40px",
            }}
          >
            <Box
              component="img"
              src="/assets/BoomSMSLogo.png"
              sx={{
                display: { lg: "none" },
                height: "50px",
                objectFit: "cover",
              }}
            />
            <Box
              component="form"
              sx={{ width: "100%" }}
              onSubmit={handleSubmit}
            >
              <Typography
                variant="h6"
                sx={{ color: "primary.main", fontWeight: 700 }}
              >
                Reset your password
              </Typography>
              <Typography sx={{ color: "secondary.light", mt: "0.5rem" }}>
                Enter the email address you used to register.
              </Typography>
              <Typography
                variant="subtitle2"
                fontWeight="bold"
                sx={{
                  marginTop: "20px",
                  marginBottom: "8px",
                  color: emailValidateError
                    ? "secondary.errorText"
                    : "secondary.light",
                }}
              >
                Email
              </Typography>
              <TextField
                size="small"
                fullWidth
                name="email"
                value={queryEmail ? queryEmail : data.email}
                onChange={handleOnChange}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused fieldset": {
                      borderColor: emailValidateError
                        ? "secondary.errorText"
                        : "primary",
                    },
                    "& fieldset": {
                      borderColor: emailValidateError
                        ? "secondary.errorText"
                        : "primary",
                    },
                  },
                  input: {
                    color: emailValidateError
                      ? "secondary.errorText"
                      : "primary",
                  },
                }}
              />
              <Typography
                sx={{
                  color: "secondary.errorText",
                  fontSize: "0.8rem",
                  mt: "0.5rem",
                }}
              >
                {emailValidateError}
              </Typography>
              <Box>
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  sx={{
                    marginTop: "20px",
                    marginBottom: "8px",
                    color: "secondary.light",
                  }}
                >
                  OTP
                </Typography>
                <Stack direction="row" gap="1rem" alignItems="center">
                  <OTPInputField
                    length={6}
                    onComplete={handleComplete}
                    queryOtp={queryOtp || ""}
                  />
                  <LoadingButton
                    variant="contained"
                    disableElevation
                    loading={!emailValidateError ? isEmailLoading : null}
                    onClick={handleSendOtp}
                    disabled={timer > 0}
                    sx={{
                      width: "50%",
                      fontWeight: 700,
                    }}
                  >
                    {timer > 0 ? formatTime(timer) : "Get"}
                  </LoadingButton>
                </Stack>
              </Box>
              <Box height="25px" />
              <Stack direction="row" gap={2}>
                <Button
                  variant="outlined"
                  onClick={() => navigate("/login")}
                  disableElevation
                  sx={{
                    width: "50%",
                    fontWeight: 700,
                  }}
                >
                  back
                </Button>
                <LoadingButton
                  variant="contained"
                  disableElevation
                  loading={isResetPasswordLoading}
                  type="submit"
                  sx={{
                    width: "50%",
                    fontWeight: 700,
                  }}
                >
                  reset password
                </LoadingButton>
              </Stack>
            </Box>
          </Container>
        </Box>
        {/*-----------------BACKGROUND IMGAES---------------------------------------*/}
        <Box
          component="img"
          src="/assets/plane.svg"
          sx={{
            display: { xs: "none", lg: "block" },
            height: "100vh",
            objectFit: "cover",
            position: "absolute",
            right: "-50px",
            overflow: "hidden",
            zIndex: 1,
          }}
        />
        <Box
          component="img"
          src="/assets/login-vector.png"
          sx={{
            display: { lg: "none" },
            height: "30vh",
            opacity: 0.1,
            objectFit: "cover",
            position: "absolute",
            overflow: "hidden",
            zIndex: 1,
          }}
        />
      </Box>
    </Box>
  );
};

export default ResetPassword;
