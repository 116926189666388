import { Navigate } from "react-router-dom";

function RedirectRoute({ component: Component }) {
  const otpToken = localStorage.getItem("otpToken");
  if (otpToken) {
    return <Component />;
  } else {
    return <Navigate to="/resetpassword" replace />;
  }
}

export default RedirectRoute;
