import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    // use for mui components like Button and input
    primary: {
      main: "#f5c226",
      light: "#ffffe0",
      dark: "#FFD700",
    },
    // use for typo color and line
    secondary: {
      main: "#14216b",
      light: "#333",
      errorText: "#F26073",
    },
  },
});
