import Login from "./containers/Login";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Register from "./containers/Register";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
  Legend,
} from "chart.js";
import { CustomSnackbar } from "./hocs/CustomSnackbar";
import { QueryClient, QueryClientProvider } from "react-query";
import PrivateRoute from "./hocs/PrivateRoute";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { lazy } from "react";
import RedirectRoute from "./hocs/RedirectRoute";
import ResetPassword from "./containers/ResetPassword";
import ChangePassword from "./containers/ChangePassword";

const Dashboard = lazy(() => import("./containers/Dashboard"));

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  ArcElement,
  Legend
);

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <CustomSnackbar>
          <Router>
            <Routes>
              <Route path="login" element={<Login />} />
              <Route path="register" element={<Register />} />
              <Route path="resetpassword" element={<ResetPassword />} />
              <Route
                path="changepassword"
                element={<RedirectRoute component={ChangePassword} />}
              />
              <Route
                path="dashboard/*"
                element={<PrivateRoute component={Dashboard} />}
              />
              <Route path="*" element={<Navigate to={"dashboard"} replace />} />
            </Routes>
          </Router>
        </CustomSnackbar>
      </LocalizationProvider>
    </QueryClientProvider>
  );
}

export default App;
